import { FIREBASE_PROJECT_ID, FIREBASE_API_KEY, FIREBASE_APP_MAJOR_VERSION, FIREBASE_APP_MINOR_VERSION, FIREBASE_APP_PATCH_VERSION} from './Env';
import React, { useEffect, useState, useCallback } from 'react';
import { Stack, Text, Link, FontWeights, IStackTokens, IStackStyles, ITextStyles } from '@fluentui/react';
import { DefaultButton, PrimaryButton, TextField } from '@fluentui/react';
import logo from './logo.svg';
import './App.css';

import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import 'firebase/compat/database';
import 'firebase/compat/functions';

const firebaseConfig = {
  apiKey: FIREBASE_API_KEY,
  // authDomain: "gb-sample-ui.firebaseapp.com",
//  databaseURL: "https://gb-sample-ui-default-rtdb.firebaseio.com",
//    databaseURL: "http://127.0.0.1:9000/?ns=gb-sample-ui-default-rtdb",
  projectId: FIREBASE_PROJECT_ID,
  // storageBucket: "gb-sample-ui.appspot.com",
  // messagingSenderId: "122104198300",
  // appId: "1:122104198300:web:61cb1677021c7a37e8a959"
  };


// Your web app's Firebase configuration gb-sample-ui-staging
// const firebaseConfig = {
//   apiKey: "AIzaSyDBeS0Jl0-p9lAXyXmJ1TQFws9Mvp-rARQ",
//   authDomain: "gb-sample-ui-staging.firebaseapp.com",
//   databaseURL: "https://gb-sample-ui-staging-default-rtdb.firebaseio.com",
//   projectId: "gb-sample-ui-staging",
//   storageBucket: "gb-sample-ui-staging.appspot.com",
//   messagingSenderId: "278608091907",
//   appId: "1:278608091907:web:134ac3d64bb4f9936b2736"
// };

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration  gb-sample-ui-staging
// const firebaseConfig = {
//   apiKey: "AIzaSyCcD6_xIkupzjgSO_Z4WwpP7e11mm1Wkdo",
//   authDomain: "gb-sample-ui-qa.firebaseapp.com",
//   databaseURL: "https://gb-sample-ui-qa-default-rtdb.firebaseio.com",
//   projectId: "gb-sample-ui-qa",
//   storageBucket: "gb-sample-ui-qa.appspot.com",
//   messagingSenderId: "971460618533",
//   appId: "1:971460618533:web:1e41da5e8623c9bf0852cc"
// };

if (window.location.hostname === 'localhost') {
  firebase.initializeApp(firebaseConfig);
  firebase.auth().useEmulator('http://localhost:9099');
  firebase.firestore().useEmulator('localhost', 8080);
  firebase.database().useEmulator('localhost', 9000);
  firebase.functions().useEmulator('localhost', 3000); // Add this line for Cloud Functions emulator
} else {
  firebase.initializeApp(firebaseConfig);  
}

const boldStyle: Partial<ITextStyles> = { root: { fontWeight: FontWeights.semibold } };
const stackTokens: IStackTokens = { childrenGap: 15 };
const stackStyles: Partial<IStackStyles> = {
  root: {
    width: '960px',
    margin: '0 auto',
    textAlign: 'center',
    color: '#605e5c',
  },
};


export const App: React.FunctionComponent = () => {
  const [response, setResponse] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  
  const handleEmailChange = (event: any) => {
    setEmail(event.target.value);
  };
  
  const handlePasswordChange = (event: any) => {
    setPassword(event.target.value);
  };  

  const handleLogin = () => {
    firebase
      .auth()
      .signInWithEmailAndPassword(email, password)
      .then((userCredential) => {
        // Login successful
        const user = userCredential.user;
        console.log('Logged in user:', user);
        console.log('User id:', user?.uid);
      })
      .catch((error) => {
        // Handle login error
        console.error('Login error:', error);
      });
  };
  
  const handleSignup = () => {
    firebase
      .auth()
      .createUserWithEmailAndPassword(email, password)
      .then((userCredential) => {
        // Signup successful
        const user = userCredential.user;
        console.log('Signed up user:', user);
      })
      .catch((error) => {
        // Handle signup error
        console.error('Signup error:', error);
      });
  };

  // const handleResponse = (data: any) => {
  //   setResponse(data);
  //   console.log('Response:', response);
  // };

  const handleResponse = useCallback((data) => {
    setResponse(data);
    console.log('Response:', response);
  }, [response]);

  // const callCloudFunction = async () => {
  //   try {
  //     const response = await firebase.functions().httpsCallable('helloWorld')();      
  //     handleResponse(response.data);
  //   } catch (error) {
  //     console.error('Error:', error);
  //   }
  // };

  const callCloudFunction = useCallback(async () => {
    try {
      const response = await firebase.functions().httpsCallable('helloWorld')();
      handleResponse(response.data);
    } catch (error) {
      console.error('Error:', error);
    }
  }, [handleResponse]);

  const callCloudFunction1 = useCallback(async () => {
    try {
      const response = await firebase.functions().httpsCallable('writeToCustomCollection')();
      handleResponse(response.data);
    } catch (error) {
      console.error('Error:', error);
    }
  }, [handleResponse]);

  const callCloudFunction2 = useCallback(async () => {
    try {
      const response = await firebase.functions().httpsCallable('writeToCustomDatabase')();
      handleResponse(response.data);
    } catch (error) {
      console.error('Error:', error);
    }
  }, [handleResponse]);

  const callCloudFunction3 = useCallback(async () => {
    try {
      const response = await firebase.functions().httpsCallable('writeToFirebaseStorage')();
      handleResponse(response.data);
    } catch (error) {
      console.error('Error:', error);
    }
  }, [handleResponse]);


  const callCloudFunction4 = useCallback(async () => {
    try {
      const publishMessage = firebase.functions().httpsCallable('publishMessage');
      const response = await publishMessage({
        message: 'hello message'
      });
      handleResponse(response.data);
    } catch (error) {
      console.error('Error:', error);
    }
  }, [handleResponse]);

  const callCloudFunction5 = useCallback(async () => {
    try {
      const publishMessage = firebase.functions().httpsCallable('trigger');
      const response = await publishMessage({
        message: 'hello message'
      });
      handleResponse(response.data);
    } catch (error) {
      console.error('Error:', error);
    }
  }, [handleResponse]);


  // const callCloudFunction = async () => {
  //   try {
  //     const response = await fetch('http://localhost:5001/gb-sample-ui/us-central1/helloWorld', {
  //       mode: 'cors', // Set the mode to 'cors' to enable CORS
  //     });
  
  //     if (!response.ok) {
  //       throw new Error('Request failed');
  //     }
  
  //     const data = await response.json();
  //     handleResponse(data);
  //   } catch (error) {
  //     console.error('Error:', error);
  //   }
  // };

  useEffect(() => {
    firebase.firestore().collection('test-custom-collection')
    .onSnapshot((snapshot) => {
      snapshot.docChanges().forEach((change) => {
        if (change.type === 'added') {
          // Document added
          console.log('Added:', change.doc.data());
        }
        if (change.type === 'modified') {
          // Document modified
          console.log('Modified:', change.doc.data());
        }
        if (change.type === 'removed') {
          // Document removed
          console.log('Removed:', change.doc.data());
        }
      });
    });

    // const databaseRef = firebase.database().ref('test-custom-collection/test-custom-document-id');

    // // Attach a listener for value changes in the database
    // databaseRef.on('value', (snapshot) => {
    //   // Handle the data change here
    //   const data = snapshot.val();
    //   console.log('Database changed:', data);
    // });

    const databaseRef = firebase.database().ref('test-custom-collection');

    databaseRef.on('child_added', (snapshot) => {
      const documentData = snapshot.val();
      console.log('New document added:', documentData);
    });
    

    callCloudFunction();
    callCloudFunction1();
    callCloudFunction2();
    callCloudFunction3();
    callCloudFunction4();
    callCloudFunction5();

    console.log("Ver", FIREBASE_APP_MAJOR_VERSION, FIREBASE_APP_MINOR_VERSION, FIREBASE_APP_PATCH_VERSION);
  
    // const databaseRef = firebase.database().ref('test-custom-collection/test-custom-document-id');

    // // Attach a listener for value changes in the database
    // const listener = databaseRef.on('value', (snapshot) => {
    //   // Log a message in the console
    //   console.log('Database changed:', snapshot.val());
    //   console.log('Listener changed:', listener);
    // });




  }, [callCloudFunction, callCloudFunction1, callCloudFunction2, callCloudFunction3, callCloudFunction4, callCloudFunction5]);
  return (
    <Stack horizontalAlign="center" verticalAlign="center" verticalFill styles={stackStyles} tokens={stackTokens}>
      <img className="App-logo" src={logo} alt="logo" />
      <Text variant="xxLarge" styles={boldStyle}>
        Welcome to your Fluent UI sample web-app - Test2  
      </Text>
      <Text variant="large">For a guide on how to customize this project, check out the Fluent UI documentation.</Text>
      <Text variant="large" styles={boldStyle}>
        Essential links
      </Text>
    <TextField label="Email" value={email} onChange={handleEmailChange} />
    <TextField label="Password" type="password" value={password} onChange={handlePasswordChange} />
    <Stack horizontal tokens={stackTokens} horizontalAlign="center">
      <PrimaryButton onClick={handleLogin}>Login</PrimaryButton>
      <DefaultButton onClick={handleSignup}>Signup</DefaultButton>
    </Stack>

      <Stack horizontal tokens={stackTokens} horizontalAlign="center">
        <Link href="https://developer.microsoft.com/en-us/fluentui#/get-started/web">Docs</Link>
        <Link href="https://stackoverflow.com/questions/tagged/office-ui-fabric">Stack Overflow</Link>
        <Link href="https://github.com/microsoft/fluentui/">Github</Link>
        <Link href="https://twitter.com/fluentui">Twitter</Link>
      </Stack>
      <Text variant="large" styles={boldStyle}>
        Design system
      </Text>
      <Stack horizontal tokens={stackTokens} horizontalAlign="center">
        <Link href="https://developer.microsoft.com/en-us/fluentui#/styles/web/icons">Icons</Link>
        <Link href="https://developer.microsoft.com/en-us/fluentui#/styles/web">Styles</Link>
        <Link href="https://aka.ms/themedesigner">Theme designer</Link>
      </Stack>
    </Stack>
  );
};
